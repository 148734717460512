import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, OperatorFunction, map, tap } from "rxjs";
import { environment } from "../../../environments/environment";
import { Trip } from "src/app/features/private/dashboard/models/dashboard.model";
import { BrandConfig } from "src/app/models/brand-config.model";

const API_PREFIX:string = "/api/v1/";

function mapResponseWithDefault<T, R>(
  predicate: (response: T) => R,
  fallback: R
): OperatorFunction<T, R> {
  return (source: Observable<T>): Observable<R> =>
    source.pipe(
      map((response: T) => {
        try {
          return predicate(response);
        } catch (error) {
          return fallback;
        }
      })
    );
}


@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private _apiPrefix: string =  API_PREFIX;
  constructor(private http: HttpClient) {
  }

  private _generateUrl(urlPart: string, apiPrefix?:string): string{
    apiPrefix = apiPrefix || this._apiPrefix;
    return environment.apiUrl + apiPrefix + urlPart;
  }

  getTrips(): Observable<Trip[]> {
    const img = (id: number) => 'https://loremflickr.com/1200/650/wildlife?random=' + id;
    const url = this._generateUrl(`itinerary/trip`);

    return this.http.get(url)
      .pipe(
        mapResponseWithDefault((resp: any) => resp.response.items, []),
        map((trips: Trip[]) => {
          let tripsWithImg: Trip[] = trips.map((t: Trip, id: number) => ({ ...t, img: img(id + 1) }));
          return tripsWithImg;
        })
      );
  }

  getProfile(): Observable<any> {
    const url = this._generateUrl(`profile/search`);
    let profileExtra = {
      icon: "profile-3"
    }
    return this.http.get(url).pipe(
      mapResponseWithDefault((resp: any) => resp.response[0], null),
      map(p => ({
      ...profileExtra,
      ...p,
    })));
  }

  resetProfilePasswordWithRedirectUrl(connectionId: string): Observable<string| null> {
    const url = this._generateUrl("management/user/updatepasswordlink");

    const params = new URLSearchParams();

    params.set("clientId", environment.auth0.clientId);
    params.set("connectionId", connectionId);

    const urlWithParams = `${url}?${params.toString()}`;
    // response
    return this.http.get<{response:{ticket:string}}>(urlWithParams).pipe(
      mapResponseWithDefault((resp) => resp.response.ticket, null),
    );
  }

  getHelp(): Observable<any> {
    const url = `${environment.apiUrl}/help/search`;

    return this.http.get(url);
  }

  /*
  * Get Brand Config by Identifier
  * Identifier can be organizationId or domainName like portal.kt.local or
  * portal.kt.ci
  */
  getBrandConfigByIdentifier(identifier: string): Observable<BrandConfig> {
    if (identifier == null || identifier == "") {
      return new Observable<BrandConfig>();
    }
    const url = `${environment.brandEngineUrl}/Brand/${identifier}`;

    return this.http.get(url).pipe(map((response: any) => response?.result as BrandConfig));
  }
}
