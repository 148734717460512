
import { createSelector } from "@ngrx/store";
import { selectGlobalFeatureState } from './get-feature';
import { selectIsMobile } from "./global-common.selectors";

export const selectGlobalLayoutState = createSelector(selectGlobalFeatureState, (state) => state.layout);

export const selectIsToolbarActive = createSelector(selectGlobalLayoutState, (state) => state.isToolbarActive);

export const selectShowDesktopNav = createSelector(
  selectGlobalLayoutState,
  selectIsMobile,
  (lytState, isMob) => lytState.isToolbarActive || isMob ? false : true)

export const selectShowMobNav = createSelector(
  selectGlobalLayoutState,
  selectIsMobile,
  (lytState, isMob) => lytState.isToolbarActive ? false : isMob);

export const selectIsAppBarHiddenOnMobile = createSelector(
  selectGlobalLayoutState,
  selectIsMobile,
  (lytState, isMob) => (lytState.isToolbarActive && isMob) ? true : false);
