import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { DashboardLayoutComponent } from './core/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    pathMatch: 'prefix',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./features/public/login/login.module').then(m => m.LoginModule),
      },
      {
        path: '',
        pathMatch: 'prefix',
        component: DashboardLayoutComponent,
        children: [
          {
            path: 'dashboard',
            loadChildren: () => import('./features/private/dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: [AuthGuard],
          },
          {
            path: 'profile',
            loadChildren: () => import('./features/private/profile/profile.module').then(m => m.ProfileModule),
            canActivate: [AuthGuard],
          },
          {
            path: '**',
            redirectTo: 'dashboard',
          },
        ]
      },

      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
