import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import {RouterModule} from "@angular/router";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {A11yModule} from '@angular/cdk/a11y';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatRippleModule} from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrandLogoComponent } from './components/brand-logo/brand-logo.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { SecondaryNavComponent } from './components/secondary-nav/secondary-nav.component';

// Layouts
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { PortalModule } from '@angular/cdk/portal';
import { LayoutModule } from '../layout/layout.module';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BrandLogoComponent,
    SecondaryNavComponent,
    // Layouts
    MainLayoutComponent,
    DashboardLayoutComponent
  ],
  imports: [
    CommonModule,
    A11yModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTabsModule,
    MatDividerModule,
    LayoutModule,
    PortalModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CoreModule { }

