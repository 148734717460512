import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

export interface LinkItem {
  path: string;
  icon: string;
  label: string;
}

@Component({
  selector: 'secondary-nav',
  templateUrl: './secondary-nav.component.html',
  styleUrls: ['./secondary-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecondaryNavComponent {

  @Input() links: Array<LinkItem> = [];
  @Input() isMobile!: boolean;

}
