import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { ApiService } from "../../../core/api/api.service";
import { loadUserProfile, loadUserProfileFailure, loadUserProfileSuccess, resetProfileProfilePassword, updateProfilePhoneNumber, updateProfilePhoneNumberSuccess } from "../actions/profile.actions";
import { setAppInitialized } from "../../global/actions/global-common.actions";
import { Store } from "@ngrx/store";
import { selectBrandTemplate } from "../../global/selectors/global-common.selectors";

@Injectable()
export class ProfileEffects {

  constructor(private actions$: Actions, private apiService: ApiService, private store: Store) { }

  appInit$ = createEffect(() => this.actions$.pipe(
    ofType(setAppInitialized),
    map(() => loadUserProfile())
  ))

  loadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserProfile),
    switchMap(() =>
      this.apiService.getProfile()
        .pipe(
          map((profile: any) =>
            loadUserProfileSuccess({ profile })
          ),
          catchError((error) =>
            of(loadUserProfileFailure({ error }))
          )
        )
    )
  ));


  updateProfilePhoneNumber$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfilePhoneNumber),
    switchMap((action) => {
      return of(updateProfilePhoneNumberSuccess(action));
    })
  ));

  resetProfilePasswordWithRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(resetProfileProfilePassword),
    withLatestFrom(this.store.select(selectBrandTemplate)),
    switchMap(([action, brandConfig]) => {
      return this.apiService.resetProfilePasswordWithRedirectUrl((brandConfig && brandConfig.auth0 && brandConfig.auth0.connectionId) || "").pipe(
        tap((result) => {

          // redirect to change password
          if (result) {
            window.location.href = result;
          }

        })
      );
    })
  ), { dispatch: false });

}
