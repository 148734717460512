
export interface Image {
  url?: string;
  altText?: string;
}


export interface Link {
  key: string;
  url: string;
  label: string;
  icon: string;
}


export interface BrandImages {
  primaryLogo: Image;
  primaryLogoDark: Image;
  favicon: Image;
  poweredBy: Image;
}

export interface ContentLinkGroup {
  title: string;
  links: Link[];
}


export interface BrandLinks {
  contentLinks: ContentLinkGroup[];
  appStoreLinks: Image[];
  socialLinks: Link[];
  trustPilot: Image;
}

export interface BrandLinkGroups {
  social: {
    title: string;
    links: Link[];
  },
  legal: {
    title: string;
    links: Link[];
  },
  resources: {
    title: string;
    links: Link[];
  },
  help: {
    title: string;
    links: Link[];
  },
  mobileApps: {
    title: string;
    links: Link[];
  },
  other: {
    title: string;
    links: Link[];
  }
}


export interface BrandColors {
  [key: string]: {
    hex: string;
  };
}

export interface BrandTextEntry {
  emailSignature: {
    lang: string;
    text: string;
  };
}

export interface BrandDomain {
  key: string;
  domainName: string;
}

export interface FontFamily {
  default: {
    fontFamily: string;
  },
  display: {
    fontFamily: string;
  }
}

export interface BrandPoweredBy {
  name: string;
  image: Image;
  key: string;
  url: string;
  label: string;
  icon: string;
}

export interface BrandConfig {
  brandCode: string;
  name: string;
  legalName?: string;
  images: BrandImages;
  colors: BrandColors;
  linkGroups: BrandLinkGroups;
  poweredBy: BrandPoweredBy;
  textEntries: BrandTextEntry;
  domains: BrandDomain[];
  dateModified: string;
  fonts: FontFamily;
  auth0: {
    tenant: string;
    connectionId: string;
    connectionName: string;
    organizationId: string;
    organizationName: string;
  }
  organization?: string;
}


export class AppConfig {
  constructor(public brandConfig: BrandConfig) { }
}
