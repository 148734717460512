import { createReducer, on } from "@ngrx/store";
import { Profile } from "../../../models/profile.models";
import { loadUserProfileSuccess, loadUserProfileFailure, loadUserProfile, updateProfilePhoneNumberSuccess } from "../actions/profile.actions";

export interface ProfileFeatureState {
  profile: Profile | null;
  error: any;
  loading: boolean;
}

export const initialState: ProfileFeatureState = {
  profile: null,
  error: null,
  loading: false
};

export const profileReducer = createReducer(
  initialState,
  on(loadUserProfile, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadUserProfileSuccess, (state, { profile }) => ({
    ...state,
    profile: profile,
    loading: false,
  })),
  on(loadUserProfileFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(updateProfilePhoneNumberSuccess, (state, { newNumber }) => ({
    ...state,
    profile: state.profile ? {
      ...state.profile,
      mobileNumber: {
        numberVerified: false,
        number: newNumber
      }
    } : null,
    loading: false,
  })),
);
