import { combineReducers } from "@ngrx/store";
import * as fromCommon from './global-common.reducers';
import * as fromLayout from './global-layout.reducers';

export interface GlobalFeatureState {
  common: fromCommon.GlobalCommonState;
  layout: fromLayout.GlobalLayoutState;
}

export const globalFeatureReducer = combineReducers<GlobalFeatureState>({
  common: fromCommon.globalCommonReducer,
  layout: fromLayout.globalLayoutReducer
})
