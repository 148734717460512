import { Component, Input } from '@angular/core';
import { LinkTarget } from 'src/app/enums/link-target.enum';
import { Footer } from 'src/app/models/global.model';

@Component({
  selector: 'cp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() data!: Footer;
  linkTarget = LinkTarget;

  currentYear = new Date().getFullYear();

  get brand() {
    return this.data && this.data.logo;
  }

  get trustPilot() {
    return this.data && this.data.trustPilot;
  }

  get generalLinks() {
    return this.data && this.data.generalLinks || [];
  }

  get appStoreLinks() {
    return this.data && this.data.appStoreLinks || [];
  }

  get socialLinks() {
    return this.data && this.data.socialLinks || [];
  }

  get showMarketingSection() {
    return this.appStoreLinks.length !== 0 || this.socialLinks.length !== 0;
  }

}
