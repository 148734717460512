<header *ngIf="!(isAppBarHidden$ | async)">
  <cp-header [data]="(header$ | async)!">
    <ng-container actions>
      <button *ngIf="isAuthenticating$ | async" mat-button (click)="logout()">Logout</button>
    </ng-container>
  </cp-header>
</header>

<main [class.offset-app-bar]="!(isAppBarHidden$ | async)">
  <!-- Toolbar -->
  <ng-container *cdkPortalOutlet="toolbarPortal"></ng-container>

  <router-outlet></router-outlet>
</main>

<cp-footer *ngIf="(footer$ | async) as footer" [data]="footer">
</cp-footer>


<!-- Bottom navbar -->
<ng-container *cdkPortalOutlet="bottomToolbarPortal"></ng-container>
