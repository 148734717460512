import { ColorOperationConfig, ColorOperations, ColorPalette } from "src/app/models/theme.models";
import * as chroma from "chroma-js";

export const OPERATIONS: ColorOperations = {
  ALPHA: (color: any, value: number) => chroma(color).alpha(value).hex(),
  DARKEN: (color: any, value: number) => chroma(color).darken(value).hex(),
  CONTRAST: (color: any) =>
    chroma.contrast(color, 'white') > chroma.contrast(color, 'black')
      ? "#fff"
      : "#000",
};


export function generateColorPalette(baseColor: string, config: ColorOperationConfig): ColorPalette {
  let colors: ColorPalette = {};

  for (let colorName in config) {
    let colorConfig = config[colorName];
    let color = baseColor;
    for (let operation of colorConfig) {
      if (OPERATIONS[operation.type]) {
        color = OPERATIONS[operation.type](color, operation.value);
      }
    }
    colors[colorName] = color;
  }

  return colors;
}
