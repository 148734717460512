import { ENV } from "./env.enum";

const brandIdentifier = extractBrandIdentifierFromURL(window.location.origin);
const brandEngineUrl = `https://brandengine-api.dev.triparcdev.com`;

export const environment = {
  production: false,
  env: ENV.Dev,
  apiUrl: 'https://graphlayer-api.dev.triparcdev.com',
  brandEngineUrl: brandEngineUrl,
  brandIdentifier: brandIdentifier,
  legacyKTPassThroughUrl: 'http://my.kensingtontours.ci.kt.tld/cppassThrough',
  legacyPGPassThroughUrl: 'http://my.privateguided.ci.kt.tld/cppassThrough',
  auth0: {
    domain: "auth.uat.triparcservices.com",
    clientId: "0Us3w9TmUYET67FGeTo17FnE7zcj00pz",
    connection: "kensingtontours",
    httpInterceptor: {
      allowedList: ['/api/*',]
    },
    authorizationParams: {
      audience: 'https://client.triparc-uat.com/api/v2/',
      redirect_uri: window.location.origin,
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  },
}


function extractBrandIdentifierFromURL(url: string) {
  const matches = url.match(/\/\/([^:/]+)/);
  return matches ? matches[1] : "portal.kt.local";
}
