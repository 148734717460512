import { createAction, props } from "@ngrx/store";
import { Trip } from "src/app/features/private/dashboard/models/dashboard.model";

const module = "Trips";
const apiModule = `${module} Api`;

export const getAllTrips = createAction(`[${module}] Get All Trips`);

export const getTripsFromCache = createAction(`[${module}] Get From Cache`);

export const getAllTripsSuccess = createAction(`[${apiModule}] Get All Trips Success`,  props<{ trips: Trip[], lastUpdated?: Date  }>());

export const getAllTripsFailure = createAction(`[${apiModule}] Get All Trips Failure`,  props<{ error: any  }>());
