import { Component, Input, OnInit } from '@angular/core';
import { Header } from 'src/app/models/global.model';

@Component({
  selector: 'cp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() data!: Header;

  ngOnInit(): void {
  }

  get logo() {
    return this.data && this.data.logo;
  }
}
