import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { helpFeatureKey } from './feature-key';
import { helpReducer } from './reducers/help.reducer';
import { HelpEffects } from './effects/help.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(helpFeatureKey, helpReducer),
    EffectsModule.forFeature([HelpEffects]),
  ],
  declarations: []
})
export class HelpStoreModule { }
