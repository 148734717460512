import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { GlobalCommonEffects } from './effects/global-common.effects';
import { globalFeatureKey } from './feature-key';
import { globalFeatureReducer } from './reducers';
import { GlobalLayoutEffects } from './effects/global-layout.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(globalFeatureKey, globalFeatureReducer),
    EffectsModule.forFeature([GlobalCommonEffects, GlobalLayoutEffects]),
  ],
})
export class GlobalStoreModule { }
