<div class="mdc-nav-tab" [ngClass]="{'mdc-nav-tab--mob mat-elevation-z2': isMobile}">
  <nav mat-tab-nav-bar disablePagination [mat-stretch-tabs]="isMobile ? true : false" disableRipple="true"
    [tabPanel]="tabPanel">
    <a mat-tab-link
    *ngFor="let link of links"
    [routerLink]="[link.path]"
    routerLinkActive
    #rla="routerLinkActive"
    [routerLinkActiveOptions]="{ exact: false }"
    [active]="rla.isActive" >
      <div class="mdc-tab__icon-wrapper">
        <mat-icon fontSet="material-icons-outlined">{{
          link.icon }}</mat-icon>
      </div>
      <div class="mdc-tab__text-label-text">
        {{ link.label }}
      </div>
    </a>
  </nav>
</div>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
