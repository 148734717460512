import { Injectable, TemplateRef } from '@angular/core';
import { EMPTY, ReplaySubject, tap } from 'rxjs';

export enum LayoutSections {
  Toolbar = 'toolbar',
  BottomToolbar = 'bottom-toolbar'
}

export interface TemplateStackItem {
  template: TemplateRef<any>;
  id: string;
}


type SectionSubjects = {
  [key in LayoutSections]: ReplaySubject<TemplateRef<any> | null>;
};


class TemplateStack extends Map<string, TemplateStackItem[]> {
  getMap(key: string): TemplateStackItem[] {
    return this.get(key) ?? [];
  }

  addItem(
    key: string,
    template: TemplateRef<any>,
    id: string
  ): void {
    const templates = this.getMap(key);
    const updated = [...templates, { template, id }];
    this.set(key, updated);
  }

  removeItem(key: string, id: string): void {
    const templates = this.getMap(key);
    const updated = templates.filter((item) => item.id !== id);
    this.set(key, updated);
  }

  setMap(key: string, templates: TemplateStackItem[]): void {
    this.set(key, templates);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private sectionStack = new TemplateStack();
  private sectionSubjects: SectionSubjects = {
    [LayoutSections.Toolbar]: new ReplaySubject<TemplateRef<any>| null>(1),
    [LayoutSections.BottomToolbar]: new ReplaySubject<TemplateRef<any>| null>(1)
  };

  constructor() { }


  registerSection(sectionKey: LayoutSections, template: any, itemId: string): void {
    if (this.sectionSubjects[sectionKey]) {
      this.sectionStack.addItem(sectionKey, template, itemId);
      this.sectionSubjects[sectionKey].next(template);
    }
  }

  public clearSection(sectionKey: LayoutSections, itemId: string): void {
    this.sectionStack.removeItem(sectionKey, itemId);
    const sectionMap = this.sectionStack.getMap(sectionKey);
    const sectionTemplate =
      sectionMap.length > 0 ? sectionMap[sectionMap.length - 1].template : null;

    this.sectionSubjects[sectionKey].next(sectionTemplate);
  }

  getSectionObservable(key: LayoutSections) {
    if (this.sectionSubjects[key]) {
      return this.sectionSubjects[key].asObservable();
    }
    return EMPTY;
  }
}
