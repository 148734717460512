import { AuthConfig } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { AppConfig } from "./models/brand-config.model";
import { setAppInitialized, setBrandConfig, setAppLoading } from "./store/global/actions/global-common.actions";

export function initializeAppFactory(store: Store, config: AppConfig): () => Promise<unknown> {

  return () => new Promise((resolve) => {
    store.dispatch(setAppInitialized());
    // Set Brand config
    store.dispatch(setBrandConfig({ config: config.brandConfig }));
    store.dispatch(setAppLoading({ loading: false }));

    resolve(null);
  });
}

export function authConfigServiceProviderFactory(config: AppConfig): AuthConfig {
  const { brandConfig } = config;
  // Set Auth0 config
  const auth0ConfigDefault = (environment.auth0 as AuthConfig ?? {})
  return {
    ...auth0ConfigDefault,
    authorizationParams: {
      ...auth0ConfigDefault.authorizationParams,
      organization: brandConfig.auth0.organizationId
    }
  } as AuthConfig;
}
