import { createReducer, on } from "@ngrx/store";
import { getHelp, getHelpFailure, getHelpSuccess } from "../actions/help.actions";
import { Help } from "src/app/models/help.models";

export interface HelpFeatureState {
  help: Help[] | null;
  error: any;
  loading: boolean;
}

export const initialState: HelpFeatureState = {
  help: [
    {
      icon: 'phone',
      title: 'Call',
      htmlDescription: 'Reach out to 1 888 903 2001',
      contactInfo: '+1-888-903-2001',
      contactType: 'phone',
      onContactInfoClick: () => {
        console.log('Phone function got called');

        // To workaround this Typescript issue:
        // https://github.com/microsoft/TypeScript/issues/48949
        const win: Window = window;
        win.location = "tel:+1-888-903-2001";
      }
    },
    {
      icon: 'mail',
      title: 'Email',
      htmlDescription: 'Send an email to our team',
      contactInfo: 'test-email@trip-arc.com',
      contactType: 'email',
      onContactInfoClick: () => {
        console.log('Email function got called');

        // To workaround this Typescript issue:
        // https://github.com/microsoft/TypeScript/issues/48949
        const win: Window = window;
        win.location = "mailto:test-email@trip-arc.com";
      }
    },
  ],
  error: null,
  loading: false
};

export const helpReducer = createReducer(
  initialState,
  on(getHelp, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(getHelpSuccess, (state, { help }) => ({
    ...state,
    help: help,
    loading: false,
  })),
  on(getHelpFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  }))
);
