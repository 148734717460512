import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { Breakpoints, BreakpointsDisplayMap } from "src/app/models/global.model";
import { BreakpointObserver } from "@angular/cdk/layout";
import { loadBrandConfigByOrganizationId, loadBrandConfigByOrganizationIdFailure, loadBrandConfigByOrganizationIdSuccess, setBrandConfig, updateViewportBreakpoint } from "../actions/global-common.actions";
import { ApiService } from "src/app/core/api/api.service";
import { Store, select } from "@ngrx/store";
import { selectBrandTheme } from "../selectors/global-common.selectors";
import { BrandTheme } from "src/app/models/theme.models";
import { ThemeService } from "src/app/core/services/theme.service";


@Injectable()
export class GlobalCommonEffects {
  constructor(
    private actions$: Actions,
    private breakpointObserver: BreakpointObserver,
    private apiService: ApiService,
    private store: Store,
    private themeService: ThemeService) { }


  // Breakpoints
  breakpoint$ = createEffect(() => {
    return this.breakpointObserver
      .observe([Breakpoints.XS, Breakpoints.MD, Breakpoints.LG])
      .pipe(map((result) => {

        const breakpoint = Object.keys(result.breakpoints).find((query) => result.breakpoints[query]) ?? "";
        let currBreakpoint = "";
        currBreakpoint = BreakpointsDisplayMap[breakpoint] ?? 'xs';//BreakpointsDisplayMap.get(currentBreakpoint) ?? 'xs';
        return updateViewportBreakpoint({ breakpoint: currBreakpoint })
      }))
  });

  loadBrandConfigByOrganizationId$ = createEffect(() => this.actions$.pipe(
    ofType(loadBrandConfigByOrganizationId),
    switchMap((action) => {
      return this.apiService.getBrandConfigByIdentifier(action.organizationId).pipe(
        map((config) => {
          return loadBrandConfigByOrganizationIdSuccess({ config });
        }),
        catchError((error: any) => {
          return of(loadBrandConfigByOrganizationIdFailure(error));
        })
      );
    })
  ));

  // Theme configure
  configureTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setBrandConfig),
        concatLatestFrom(() => this.store.pipe(select(selectBrandTheme))),
        tap(([_, theme]: [any, BrandTheme]) => {
          this.themeService.configureTheme(theme);
        }),
      ),
    { dispatch: false }
  );
}
