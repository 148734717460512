import { createAction, props } from "@ngrx/store";
import { Profile } from "../../../models/profile.models";

const module = "Profile";
const apiModule = `${module} Api`;

export const loadUserProfile = createAction(`[${module}] Load Profile`);
export const loadUserProfileSuccess = createAction(`[${apiModule}] Load Profile Success`, props<{ profile: Profile }>());
export const loadUserProfileFailure = createAction(`[${apiModule}] Load Profile Failure`, props<{ error: any }>());

export const updateProfilePhoneNumber = createAction(`[${module}] Update Profile Phone Number`, props<{ newNumber: string }>());
export const updateProfilePhoneNumberSuccess = createAction(`[${apiModule}] Update Profile Phone Number Success`, props<{ newNumber: string }>());
export const updateProfilePhoneNumberFailure = createAction(`[${apiModule}] Update Profile Phone Number Failure`, props<{ error: any }>());

export const resetProfileProfilePassword = createAction(`[${module}] Reset Profile Password`, props<{ profileEmail: string }>());
export const resetProfileProfilePasswordSuccess = createAction(`[${apiModule}] Reset Profile Password Success`);
export const resetProfileProfilePasswordFailure = createAction(`[${apiModule}] Reset Profile Password Failure`, props<{ error: any }>());
