import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { UserAuthService } from './user-auth-service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Auth0Module.forRoot()
  ],
  exports: [Auth0Module],
  providers: [
    UserAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class AuthModule { }
