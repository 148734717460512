import { createReducer, on } from "@ngrx/store";
import { setToolBarActive } from "../actions/global-layout.actions";

export interface GlobalLayoutState {
  isToolbarActive: boolean;
};


const initialState: GlobalLayoutState = {
  isToolbarActive: false
}

export const globalLayoutReducer = createReducer(
  initialState,
  on(setToolBarActive, (state, { active }) => ({
    ...state,
    isToolbarActive: active,
  }))
);
