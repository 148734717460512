import { createSelector } from '@ngrx/store';
import { Breakpoints, BreakpointsDisplayMap, Footer, Header } from 'src/app/models/global.model';
import { BrandTheme, ThemeColors } from 'src/app/models/theme.models';
import { selectGlobalFeatureState } from './get-feature';
import { GlobalCommonState } from '../reducers/global-common.reducers';
import { BrandConfig, Image, Link } from 'src/app/models/brand-config.model';

export const selectGlobalCommonState = createSelector(selectGlobalFeatureState, (state) => state.common);

export const selectAppIsLoading = createSelector(selectGlobalCommonState, (state) => state.isLoading);

export const selectIsMobile = createSelector(selectGlobalCommonState, (state) => state.deviceBreakpoint === BreakpointsDisplayMap[Breakpoints.XS] ? true : false);

export const selectBrandConfig = createSelector(selectGlobalCommonState, (state) => state.brandConfig);

export const selectBrandConfigWithLoading = createSelector(selectGlobalCommonState, (globalState) => ({
  brandConfig: globalState.brandConfig,
  loading: globalState.isLoading
}));

export const selectBrandTheme = createSelector(selectGlobalCommonState, (state) => {
  const colors: ThemeColors = state.brandConfig?.colors ?
    Object.keys(state.brandConfig.colors).reduce((acc, key) => {
      if (state.brandConfig) {
        acc[key] = state.brandConfig.colors[key].hex;
      }
      return acc;
    }, {} as ThemeColors) : {};

  return ({
    colors: colors,
    fontFamily: state.brandConfig?.fonts?.default?.fontFamily ?? 'urbanist',
  }) as BrandTheme;
});

// Layout template selectors

export const selectBrandTemplate = createSelector(selectGlobalCommonState, (state: GlobalCommonState) => state.brandConfig);

export const selectHeader = createSelector(selectBrandTemplate, (state) => {
  const logo = state && state.images && state.images.primaryLogo;
  return {
    logo
  } as Header;
});

export const selectFooter = createSelector(selectBrandTemplate, (state) => {
  const logo = state && state.images && state.images.primaryLogoDark;
  const appStoreLinks = (state && state.linkGroups && state.linkGroups.mobileApps.links) ?? [];
  const socialLinks = (state && state.linkGroups && state.linkGroups.social.links) ?? [];
  const generalLinks = (state && state.linkGroups) ? [
    state.linkGroups.legal,
    state.linkGroups.resources,
    state.linkGroups.help,
  ] : [];
  const copyRight = state && state.legalName;
  const poweredBy = state && state.poweredBy;
  let trustPilot = undefined;
  if ((state?.linkGroups?.other?.links?.length ?? 0) > 0) {
    trustPilot = state?.linkGroups?.other?.links?.map(linkToImage)[0];
  }

  return {
    logo,
    appStoreLinks,
    socialLinks,
    generalLinks,
    copyRight,
    poweredBy,
    trustPilot
  } as Footer;
});

const linkToImage = (link: Link) => {
  return {
    altText: link.label,
    url: link.url,
    link: link.url,
  } as Image;
};
