import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, mergeMap, of, switchMap, withLatestFrom } from "rxjs";
import { ApiService } from "../../../core/api/api.service";
import { getAllTrips, getAllTripsFailure, getAllTripsSuccess, getTripsFromCache } from "../actions/trips.actions";
import { selectTripsLastUpdated } from "../selectors/trips.selectors";
import { Store } from "@ngrx/store";

const cache = new Map();

@Injectable()
export class TripsEffects {

  constructor(private actions$: Actions, private apiService: ApiService, private store: Store) { }

  loadAllTrips$ = createEffect(() => this.actions$.pipe(
    ofType(getAllTrips),
    switchMap((action) =>{
      const serialAction = JSON.stringify(action);
      if (!cache.has(serialAction)) {
        return this.apiService.getTrips()
        .pipe(
          map((trips: any) =>{
            cache.set(serialAction, trips);
            return getAllTripsSuccess({ trips, lastUpdated: new Date() })
          }
          ),
          catchError((error) =>
            of(getAllTripsFailure({ error }))
          )
        )
      }
      else{
        return of(getTripsFromCache())
      }
    })
  ));

}
