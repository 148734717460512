import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {  Header, Footer} from '../../../models/global.model';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAppBarHiddenOnMobile } from 'src/app/store/global/selectors/global-layout.selectors';
import { LayoutSections, LayoutService } from 'src/app/layout/services/layout.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { selectFooter, selectHeader } from 'src/app/store/global/selectors/global-common.selectors';
import { UserAuthService } from 'src/app/auth/user-auth-service';

@Component({
  selector: 'main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  header$!: Observable<Header | null>;
  footer$!: Observable<Footer | null>;
  isAppBarHidden$!: Observable<boolean>;
  isAuthenticating$: Observable<boolean>;
  toolbarPortal!: TemplatePortal<any> | null;
  bottomToolbarPortal!: TemplatePortal<any> | null;

  links = [
    { path: '/dashboard', label: 'My trips', icon: 'map' },
    { path: '/profile', label: 'Profile', icon: 'person' }
  ];


  constructor(
    private store: Store,
    private layoutService: LayoutService,
    private cDRef: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef,
    private authService: UserAuthService
  ) {
    this.isAppBarHidden$ = this.store.select(selectIsAppBarHiddenOnMobile);
    this.header$ = this.store.select(selectHeader);
    this.footer$ = this.store.select(selectFooter);
    this.isAuthenticating$ = this.authService.isAuthenticated$;

    this.initLayoutPortals();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logoutWithRedirect();
  }

  private initLayoutPortals() {
    this.subscriptions.push(
      this.subscribeToTemplate(
        LayoutSections.Toolbar,
        (template) => (this.toolbarPortal = this.createPortal(template))
      ),
      this.subscribeToTemplate(
        LayoutSections.BottomToolbar,
        (template) => (this.bottomToolbarPortal = this.createPortal(template))
      )
    );
  }

  private subscribeToTemplate(
    sectionName: LayoutSections,
    action: (template: TemplateRef<any> | null) => void
  ): Subscription {
    return this.layoutService
      .getSectionObservable(sectionName)
      .subscribe((template) => {
        action(template);
        this.cDRef.detectChanges();
      });
  }

  private createPortal(template: TemplateRef<any> | null): TemplatePortal<any> | null {
    return template
      ? new TemplatePortal(template, this._viewContainerRef)
      : null;
  }
}
