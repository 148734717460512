import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LinkTarget } from 'src/app/enums/link-target.enum';

@Component({
  selector: 'brand-logo',
  templateUrl: './brand-logo.component.html',
  styleUrls: ['./brand-logo.component.scss']
})
export class BrandLogoComponent {

  @Input() link?: string[] | string;
  @Input() src?: any;
  @Input() alt?: string;
  @Input() target: LinkTarget = LinkTarget.Blank;
  @Input() title?: string;
  @Input() ariaLabel?: string;
  @Input() width?: string | number;
  @Input() height?: string | number;
  @Input() useHref!: boolean;
  constructor() { }

}
