import { Directive, Input, TemplateRef } from '@angular/core';
import { LayoutSections, LayoutService } from '../services/layout.service';

let nextUniqueId = 0;

@Directive({
  selector: '[layoutSection]'
})
export class LayoutSectionDirective {
  @Input('layoutSection') section!: LayoutSections;
  private _uid!:string;

  constructor(
    private templateRef: TemplateRef<any>,
    private layoutService: LayoutService,
  ) {
    this._uid = `layout-section-${nextUniqueId++}`;
  }

  ngOnInit() {
    this.layoutService.registerSection(
      this.section,
      this.templateRef,
      this._uid
    );
  }

  ngOnDestroy(): void {
    this.layoutService.clearSection(this.section, this._uid);
  }
}
