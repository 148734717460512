import { createAction, props } from "@ngrx/store";
import { BrandConfig } from "src/app/models/brand-config.model";

const module = "Global";

export const setAppInitialized = createAction(`[${module}] App Initialized`);

export const setAppLoading = createAction(`[${module}] Set App Loading`, props<{ loading: boolean }>());

export const updateViewportBreakpoint = createAction(`[${module}] Update Viewport Breakpoint`, props<{ breakpoint: string }>());

// Brand
export const loadBrandConfig = createAction(`[${module}] Load brand config`);

export const setBrandConfig = createAction(`[${module}] Set brand config`, props<{ config: BrandConfig }>());

export const loadBrandConfigByOrganizationId = createAction(`[${module}] Load brand config for a specific organization id`, props<{ organizationId: string }>());
export const loadBrandConfigByOrganizationIdSuccess = createAction(`[${module}] Load brand config for a specific organization id Success`, props<{ config: BrandConfig }>());
export const loadBrandConfigByOrganizationIdFailure = createAction(`[${module}] Load brand config for a specific organization id Failure`, props<{ error: any }>());

