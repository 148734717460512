import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CommonModule} from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { HttpClientModule } from "@angular/common/http";
import { MatNativeDateModule } from '@angular/material/core';
import { CoreModule } from './core/core.module';
import { AppStoreModule } from './store/store.module';
import {authConfigServiceProviderFactory, initializeAppFactory} from './app-init.factory';
import { AuthModule } from './auth/auth.module';
import { AuthConfigService } from '@auth0/auth0-angular';
import { AppConfig } from './models/brand-config.model';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    HttpClientModule,
    AppStoreModule,
    AuthModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: AuthConfigService,
      deps: [AppConfig],
      useFactory: authConfigServiceProviderFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [Store, AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

