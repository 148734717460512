import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation, inject } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { selectIsToolbarActive, selectShowDesktopNav, selectShowMobNav } from 'src/app/store/global/selectors/global-layout.selectors';
import { selectIsMobile } from 'src/app/store/global/selectors/global-common.selectors';
import { LayoutSections } from 'src/app/layout/services/layout.service';

const BOTTOM_NAV_OFFSET_CLASS = 'mat-mdc-nav--fixed-bottom';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<void>();
  isMobile!: boolean;
  layoutSections = LayoutSections;

  links = [
    { path: '/dashboard', label: 'My trips', icon: 'map' },
    { path: '/profile', label: 'Profile', icon: 'person' }
  ];

  document = inject(DOCUMENT);
  showSecNav$!: Observable<boolean>;
  isMobile$!: Observable<boolean>;

  isToolbarActive$!: Observable<boolean>;
  showDesktopNavigation$!: Observable<boolean>;
  showMobNavigation$!: Observable<boolean>;

  constructor(private renderer: Renderer2, private store: Store) {
    this.isToolbarActive$ = this.store.select(selectIsToolbarActive);
    this.isMobile$ = this.store.select(selectIsMobile);
    this.showDesktopNavigation$ = this.store.select(selectShowDesktopNav);
    this.showMobNavigation$ = this.store.select(selectShowMobNav).pipe(tap((show) => { this.toggleBottomNavOffset(show); }));
  }

  toggleBottomNavOffset(active: boolean) {
    if (this.document && this.document.body && active) {
      this.renderer.addClass(this.document.body, BOTTOM_NAV_OFFSET_CLASS);
      return;
    }

    this.renderer.removeClass(this.document.body, BOTTOM_NAV_OFFSET_CLASS);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
