import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { tripsFeatureKey } from './feature-key';
import { tripsReducer } from './reducers/trips.reducer';
import { TripsEffects } from './effects/trips.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(tripsFeatureKey, tripsReducer),
    EffectsModule.forFeature([TripsEffects]),
  ],
  declarations: []
})
export class TripsStoreModule { }
