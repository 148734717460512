<ng-container *layoutSection="layoutSections.Toolbar">
  <div *ngIf="showDesktopNavigation$ | async" class="dashboard__nav">
    <div class="container">
      <secondary-nav [links]="links" class="px-sm-32 px-md-40 px-lg-80"></secondary-nav>
    </div>
    <mat-divider></mat-divider>

  </div>
</ng-container>

<ng-container *layoutSection="layoutSections.BottomToolbar">
  <div *ngIf=" (showMobNavigation$ | async)" class="dashboard__nav dashboard__nav--fixed-bottom">
    <div class="container">
      <secondary-nav [links]="links" [isMobile]="true"></secondary-nav>
    </div>
    <mat-divider></mat-divider>
  </div>
</ng-container>



<section class="dashboard__content container px-16 px-sm-32 px-md-40 px-lg-80">
  <router-outlet></router-outlet>
</section>
