import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';
import { AuthConfig, AuthConfigService } from '@auth0/auth0-angular';
import { selectAppIsLoading } from './store/global/selectors/global-common.selectors';
import { UserAuthService } from './auth/user-auth-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title: string = 'client-portal';

  appLoading$: Observable<boolean>;
  auth$Loading$: Observable<boolean>;
  showLoader$: Observable<boolean>;

  constructor(private store: Store, private authService: UserAuthService, @Optional() @Inject(AuthConfigService) public config?: AuthConfig) {
    this.appLoading$ = this.store.select(selectAppIsLoading);
    this.auth$Loading$ = this.authService.isLoading$;

    this.showLoader$ = combineLatest([this.appLoading$, this.auth$Loading$]).pipe(map(([appLoading, authLoading]: [boolean, boolean]) => appLoading || authLoading));
  }

  ngOnInit(): void {
  }
}
