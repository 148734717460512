import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BrandTheme, ColorOperationConfig, ThemeColors, ThemePalette } from 'src/app/models/theme.models';
import { generateColorPalette } from '../../utils/theme/generate-color-palette';
import { DOCUMENT } from '@angular/common';


const THEME_VAR_PREFIX = "--cp-theme-";
const THEME_STYE_ID = "theme-style";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  brandColorConfig: ColorOperationConfig = {
    "": [],
    lighter: [{ type: 'ALPHA', value: 0.1 }],
    darker: [{ type: 'DARKEN', value: 1 }],
    "contrast": [{ type: 'CONTRAST' }],
    "contrast-lighter": [{ type: 'CONTRAST' }, { type: 'ALPHA', value: 0.1 }],
    "contrast-darker": [{ type: 'CONTRAST' }]
  };

  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private readonly document: Document, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public configureTheme(theme: BrandTheme) {
    const { colors } = theme;

    if (!!colors) {
      let palettes = this._generateThemeColorPalette(colors);
      this._setThemeColorCssCustomProps(palettes);
    }
  }

  private _generateThemeColorPalette(brandColors: ThemeColors): ThemePalette[] {
    let palettes: ThemePalette[] = [];

    Object.entries(brandColors)
      .map(([colorName, baseColor]) => {
        let themeColorPalette = generateColorPalette(baseColor, this.brandColorConfig);
        palettes.push({ [colorName]: themeColorPalette });
      });

    return palettes;
  }


  private _setThemeColorCssCustomProps(palettes: ThemePalette[]) {
    let cssVarString = ``;

    palettes.forEach(palette => {
      const colorKey = Object.keys(palette)[0];
      const colorPalette = palette[colorKey];
      Object.entries(colorPalette).forEach(([colorName, color]) => {
        colorName = colorName ? `-${colorName}`: "";
        cssVarString += `${THEME_VAR_PREFIX}${colorKey}${colorName}: ${color};`;
      });
    });
    this._setCssCustomProperties(cssVarString);
  }

  private _setCssCustomProperties(propString: string) {
    let css = `:root { ${propString} }`;

    // const rootElement = this.document.documentElement;
    // this.renderer.setAttribute(rootElement, 'style', propString);
    let styleTag = this.document.getElementById(THEME_STYE_ID);
    if (!!styleTag) {
      styleTag.textContent = css;
      return;
    }

    // If it exists, replace the content
    styleTag = this.renderer.createElement('style') as HTMLStyleElement;
    styleTag.id = THEME_STYE_ID; // Setting an id for the style tag
    styleTag.textContent = css;

    // Append the style tag to the document's head
    const head = this.document.head;
    this.renderer.appendChild(head, styleTag);
  }
}
