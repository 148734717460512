import { BrandPoweredBy, ContentLinkGroup, Image, Link } from "./brand-config.model";

export const Breakpoints = {
  XS: '(max-width: 599.98px)',
  MD: '(min-width: 600px)',
  LG: '(min-width: 1480px)',
};

export const BreakpointsDisplayMap = {
  [Breakpoints.XS]: 'xs',
  [Breakpoints.MD]: 'md',
  [Breakpoints.LG]: 'lg',
}

export interface AnimationOptions {
  enterDuration?: string;
  leaveDuration?: string;
  easing?: string;
  anchor?: string;
}


export interface Header {
  logo: Image;
}

export interface Footer {
  logo: Image;
  generalLinks: ContentLinkGroup[];
  appStoreLinks: Link[];
  socialLinks: Link[];
  poweredBy: BrandPoweredBy;
  trustPilot: Image;
  copyRight: string;
}
