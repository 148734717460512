import { createReducer, on } from "@ngrx/store";
import { Trip } from "src/app/features/private/dashboard/models/dashboard.model";
import { getAllTrips, getAllTripsFailure, getAllTripsSuccess, getTripsFromCache } from "../actions/trips.actions";

export interface TripsFeatureState {
  trips: Trip[];
  error: any;
  loading: boolean;
  lastUpdated: Date | null;
}

export const initialState: TripsFeatureState = {
  trips: [],
  error: null,
  loading: false,
  lastUpdated: null,
};

export const tripsReducer = createReducer(
  initialState,
  on(getAllTrips, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(getAllTripsSuccess, (state, { trips = [], lastUpdated = new Date() }) => ({
    ...state,
    trips: [...trips],
    lastUpdated: lastUpdated,
    loading: false,
  })),
  on(getAllTripsFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(getTripsFromCache, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
);
