import { createReducer, on } from "@ngrx/store";
import { BrandConfig } from "src/app/models/brand-config.model";
import { loadBrandConfigByOrganizationId, loadBrandConfigByOrganizationIdFailure, loadBrandConfigByOrganizationIdSuccess, setAppInitialized, setAppLoading, setBrandConfig, updateViewportBreakpoint } from "../actions/global-common.actions";
import { Breakpoints, BreakpointsDisplayMap } from "src/app/models/global.model";

export interface GlobalCommonState {
  deviceBreakpoint: string;
  isLoading: boolean;
  brandConfig?: BrandConfig;
}

const initialState: GlobalCommonState = {
  deviceBreakpoint: BreakpointsDisplayMap[Breakpoints.XS],
  isLoading: true
}

export const globalCommonReducer = createReducer(
  initialState,
  on(setAppLoading, (state, { loading }): GlobalCommonState => ({
    ...state,
    isLoading: loading
  })),
  on(setBrandConfig, (state, { config }): GlobalCommonState => ({
    ...state,
    brandConfig: config,
    isLoading: false,
  })),
  on(updateViewportBreakpoint, (state, { breakpoint }): GlobalCommonState => ({
    ...state,
    deviceBreakpoint: breakpoint
  })),
  on(loadBrandConfigByOrganizationId, (state): GlobalCommonState => ({
    ...state,
    isLoading: true,
  })),
  on(loadBrandConfigByOrganizationIdSuccess, (state, { config }): GlobalCommonState => ({
    ...state,
    brandConfig: config,
    isLoading: false,
  })),
  on(loadBrandConfigByOrganizationIdFailure, (state): GlobalCommonState => ({
    ...state,
    brandConfig: {} as BrandConfig,
    isLoading: false,
  })),
);
