import { Injectable } from '@angular/core';
import { AuthService, AuthorizationParams } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserAuthService {
  private _redirectUrl: string;
  constructor(private authService: AuthService) {
    this._redirectUrl = environment.auth0.authorizationParams.redirect_uri;
  }

  logoutWithRedirect(url?: string) {

    url = url || this._redirectUrl;

    this.authService.logout({
      logoutParams: {
        returnTo: url
      },

    });
  }

  loginWithRedirect(params: AuthorizationParams) {
    this.authService.loginWithRedirect({
      authorizationParams: {
        ...params
      }
    });

  }

  getAccessTokenSilently() {
    return this.authService.getAccessTokenSilently();
  }

  get isLoading$() {
    return this.authService.isLoading$;
  }

  get isAuthenticated$() {
    return this.authService.isAuthenticated$;
  }
}
