import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { getHelpSuccess, getHelpFailure, getHelp } from "../actions/help.actions";
import { ApiService } from "src/app/core/api/api.service";

@Injectable()
export class HelpEffects {

  loadHelp$ = createEffect(() => this.actions$.pipe(
    ofType(getHelp),
    switchMap(() =>
      this.apiService.getHelp().pipe(
        map((help: any) =>
          getHelpSuccess({ help })
        ),
        catchError((error) =>
          of(getHelpFailure({ error }))
        )
      )
    )
  ));

  constructor(private actions$: Actions, private apiService: ApiService) { }

}
