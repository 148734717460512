<footer class="footer">
  <div class="container px-16 px-sm-32 px-md-40 px-lg-80">
    <!-- Main -->
    <div class="row gap-40">
      <div class="col-12 col-md-5">
        <div class="footer-brand">

          <brand-logo *ngIf="brand" [src]="brand.url" [title]="brand.altText" [link]="'/'" [alt]="brand.altText"
            [target]="linkTarget.Blank"></brand-logo>
        </div>

        <div class="mt-40 mt-md-60" *ngIf="data.trustPilot">
          <a class="footer-badge footer-badge-trustpilot" [attr.href]="data.trustPilot.url" [target]="linkTarget.Blank"
            [attr.title]="data.trustPilot.altText">
            <img class="footer-badge-img" src="/assets/images/trustpilot.png" [alt]="data.trustPilot.altText">
          </a>
        </div>
      </div>

      <div class="col-md-7">
        <div class="footer-links" *ngIf="generalLinks.length !== 0">
          <div class="footer-link-group" *ngFor="let linkGroup of generalLinks; let idx = index"
            [ngClass]="{'mt-24 mt-md-0': idx !== 0}">
            <h3 class="mat-subtitle-2">{{linkGroup.title}}</h3>
            <a class="mat-body-2 footer-link" target="_blank" [attr.href]="link.url"
              *ngFor="let link of linkGroup.links">{{link.label}}</a>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showMarketingSection">
      <div class="footer-divider mat-divider my-40 my-md-60"></div>

      <div class="row gap-40 justify-content-between align-items-center">
        <div class="col-12 col-md-auto" *ngIf="appStoreLinks.length !== 0">
          <div class="row gap-24">
            <div class="col-md-auto" *ngFor="let storeLinks of appStoreLinks; let idx = index">
              <a class="footer-badge footer-badge--lg" [attr.href]="storeLinks.url" [target]="linkTarget.Blank"
                [attr.title]="storeLinks.label">
                <img class="footer-badge-img"
                  [src]="storeLinks.key === 'ios' ? 'assets/images/badges/download-on-the-appstore.png' : 'assets/images/badges/get-it-on-google-play.png'"
                  [alt]="storeLinks.label">
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto" *ngIf="socialLinks.length > 0">
          <div class="footer-social-links">
            <a class="footer-social-link" *ngFor="let socialLink of socialLinks" [attr.href]="socialLink.url"
              [target]="linkTarget.Blank" [attr.title]="socialLink.url">
              <span class="cp-icon" [ngClass]="'cp-icon-'+socialLink.icon"></span>
            </a>
          </div>

        </div>
      </div>
    </ng-container>

    <div class="footer-divider mat-divider my-40 my-md-60"></div>

    <!-- Bottom -->
    <div class="row gap-40 justify-content-between align-items-center">
      <div class="col-md-auto">
        <p class="mat-body-2">&copy; {{currentYear}} {{data.copyRight}}</p>
      </div>

      <div class="col-md-auto" *ngIf="data && data.poweredBy">
        <a class="footer-badge footer-badge--sm" [attr.href]="data.poweredBy.url" [target]="linkTarget.Blank"
          [attr.title]="data.poweredBy.label">
          <img class="footer-badge-img" [src]="data.poweredBy.image.url" [alt]="data.poweredBy.image.altText">
        </a>
      </div>
    </div>
  </div>
</footer>