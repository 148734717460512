import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ProfileStoreModule } from './profile/profile-store.module';
import { GlobalStoreModule } from './global/global-store.module';
import { TripsStoreModule } from './trips/trips-store.module';
import { HelpStoreModule } from './help/help.store.module';
import { environment } from 'src/environments/environment';

export const featureStores = [
  ProfileStoreModule,
  GlobalStoreModule,
  TripsStoreModule,
  HelpStoreModule
]

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    ...featureStores
  ],
  declarations: []
})
export class AppStoreModule { }
