// write angular interceptor

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, concatMap, filter, first, iif, mapTo, mergeMap, of, switchMap } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private appAuth: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Immediately pass through if the request is not targeting '/api/'
    if (!req.url.includes('/api/')) {
      return next.handle(req);
    }

    const isLoaded$ = this.appAuth.isLoading$.pipe(
      filter((isLoading) => !isLoading)
    );

    return isLoaded$.pipe(
      concatMap(() =>
        iif(() => this.canAttachToken(req),
          this.appAuth.getAccessTokenSilently()
            .pipe(
              switchMap((token) => {
                const clone = token
                  ? req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      `Bearer ${token}`
                    ),
                  })
                  : req;

                return next.handle(clone);
              })
            )
          , next.handle(req)
        )
      ));

  }

  canAttachToken(request: HttpRequest<any>) {
    return request.url.includes('/api/');
  }
}
