import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutSectionDirective } from './directives/layout-section.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ LayoutSectionDirective],
  exports: [LayoutSectionDirective]
})
export class LayoutModule { }
