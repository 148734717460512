import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { AppConfig, BrandConfig } from './app/models/brand-config.model';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

function getBrandConfig(): Promise<any> {
  const url =`${environment.brandEngineUrl}/Brand/${environment.brandIdentifier}`;

  return fetch(url).then(response => {
    return response.json()
  }, _error => {
    return undefined;
  }) as Promise<any>;
}

// Fetch the brand config and then bootstrap the application
getBrandConfig().then(brandConfigResponse => {

  if (brandConfigResponse !== undefined) {

    if (environment.production) {
      enableProdMode();
    }

    const brandConfig = brandConfigResponse['result'] as BrandConfig;
    const appConfig = new AppConfig(brandConfig);

    platformBrowserDynamic([{ provide: AppConfig, useValue: appConfig }])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }
});

